body {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Inter', sans-serif;
   background-image: linear-gradient( #f4f6fa, #01040f);
}

:root {
  --color-primary: rgb(77, 77, 234);
  --color-bg: rgb(168, 245, 182);
  --color-light: #ffffff;
  --color-navbg: #000000;
  --color-logo: #de0909;
  --color-beta: #5EEAD4;
  --color-text: #E4E4E7;
  --color-footerbg: #000001;
  --color-footerborder: #52525B;
  --color-bg-dashboard: #FAF9F6;
  --color-dark: #000000;

}

/* header styling */
.logo {
  color: var(--color-logo);
  font-size: 1.3rem;
  font-weight: 700;
  text-decoration: none !important;
}
.btn-logo {
  background: none;
  border: none
}
.btn-logo:hover {
  background: none;
  color: var(--color-light)
}

.navbar>.container-fluid {
  justify-content: center;
  margin: 5px 0;
}

.reg-link {
  color: var(--color-dark)
}

.reg-link:hover {
  color: var(--color-footerborder)
}

.App {
  text-align: center;
}

.app-container {
  padding: 2rem 6rem 6rem 6rem;
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

/* #canvas {
  cursor: move;
} */

/* /headers styling */

.h1-main {
  font-size: 68px;
  font-weight: 700;
  line-height: 0.9;
  margin-bottom: 0;
}

.h3-main {
  font-size: 30px;
}

@media screen and (max-width: 920px) {
  .h1-main {
    font-size: 48px;
    font-weight: 700;
    line-height: 0.9;
    margin-bottom: 0;
  }

  .h3-main {
    font-size: 24px;
  }

  .app-container {
    padding: 2rem 0.5rem;
    text-align: center;
  }
}

/* canvas editor styling */

.outerContainer {
  width: 800px;
}

.outputDiv {
  position: relative;
  width: 100%;
  height: 0;
  padding-bottom: 100%;
}

.innerDiv {
  position: absolute;
  top: 60.3%;
  left: 59.65%;
  width: 35%;
  height: 31%;
  overflow: hidden;
}

.input {
  width: 50%;
  background-color: var(--color-light);
  color: var(--color-dark);
  box-sizing: border-box;
  font-size: 1rem;
  padding: 1rem;
  margin: 1rem 0;
}

.button1 {
  margin-top: 1rem;
  width: 50%;
  padding: 1rem;
  text-transform: uppercase;
  background-color: var(--color-light);
  color: var(--color-dark);
  font-size: 1rem;
  border-radius: 0;
  font-weight: 600;
  cursor: pointer;
}

.button1:hover {
  margin-top: 1rem;
  width: 50%;
  padding: 1rem;
  text-transform: uppercase;
  background-color: var(--color-dark);
  color: var(--color-text);
  font-size: 1rem;
  font-weight: 600;
  border-radius: 0;
  cursor: pointer;
}

@media screen and (max-width: 920px) {
  .input {
    width: 70%;
    background-color: var(--color-light);
  color: var(--color-dark);
    box-sizing: border-box;
    font-size: 1rem;
    padding: 1rem;
    margin: 0.6rem 0;
  }

  .button1 {
    margin-top: 1rem;
    width: 70%;
    padding: 1rem;
    text-transform: uppercase;
    background-color: var(--color-light);
  color: var(--color-dark);;
    font-size: 1rem;
    border-radius: 0;
    font-weight: 600;
    cursor: pointer;
  }

  .button1:hover {
    margin-top: 1rem;
    width: 70%;
    padding: 1rem;
    text-transform: uppercase;
    background-color: var(--color-dark);
  color: var(--color-text);
    font-size: 1rem;
    font-weight: 600;
    border-radius: 0;
    cursor: pointer;
  }
}

/* Footer Styling */

/* FOOTER */

.footer {
  height: 300px;
  width: 100%;
  text-align: center;
  background-color: var(--color-footerbg);
  color: var(--color-text);
  position: relative;

}

.footer-top {
  height: 200px;
  width: 80%;
  margin: 0 auto;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  text-align: left;
  border-bottom: 1px solid var(--color-footerborder);
}

/* .footer-top>div {
  width: 100%;
  display: flex;
  align-content: flex-start;
  
} */


.footer-bottom {

  width: 80%;
  height: 100px;
  margin: 0 auto;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.foot-logo {
  color: var(--color-logo);
  font-size: 1.3rem;
  margin-top: 0;
  text-decoration: none;
}


small,
small>a {
  font-size: 0.8rem;
  font-weight: 200;
  color: var(--color-light);
}

.white-text {
  color: var(--color-light);
}

@media screen and (max-width: 920px) {

  .footer {
    height: 250px;
  }

  .footer-top {
    height: 150px;
    width: 80%;
    margin: 0 auto;
    position: relative;
    display: flex;
    flex-direction:column;
    justify-content:space-around;
    align-items: center;
    text-align: center;
    border-bottom: 1px solid var(--color-footerborder);
  }


  .footer-bottom {
    width: 80%;
    height: 100px;
    margin: 0 auto;
    position: relative;
    display: flex;    
    flex-direction:column;
    align-items: center;
    justify-content:space-around;
  }
}